@import "~styles/colors";

.container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex: 1;
}

.centerButton {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 6px;
  z-index: 430;

  > img {
    width: 28px;
  }
}

.description {
  position: absolute;
  bottom: 50px;
  z-index: 430;
  width: 80%;
  left: 10%;
  background: $greyShade4;
  border-radius: 15px;
  padding: 10px;
  font-size: 13px;
  text-align: center;
  box-shadow: 0 0 15px -5px $mainBoxShadowColor;
}
