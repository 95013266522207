.navbarBrand {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.navbarTitleWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.navbarTitle {
  text-align: left;
  margin-left: 26px;
  font-family: "Lobster", sans-serif;
}

.navbarBadge {
  opacity: 0.5;
  font-weight: 600;
}

.settingsNavItem {
  margin-left: 7px;
}
