@import "~bootstrap/scss/functions";
@import "~styles/colors";

$primary: $primaryColor;

$body-bg: $greyShade3;

$theme-colors: (
  "primary": $primary,
  "secondary": $greyShade4,
  "success": $successColor,
  "danger": $dangerColor,
);

.dropdown {
  .dropdown-menu[data-bs-popper] {
    left: auto !important;
    right: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
  }
}

.dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
}

body {
  min-height: 100vh;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/bootstrap";
