@import "~styles/colors";

.panel {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 10px -5px $mainBoxShadowColor;
  overflow: hidden;
  background: $greyShade4;
}

.title {
  padding: 10px 15px 0;
  font-weight: bold;
}

.body {
  padding: 15px;
  flex: 1;
}
