@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "weather-icons/css/weather-icons.min.css";
@import "weather-icons/css/weather-icons-wind.min.css";

$fontMain: Poppins;

@mixin font(
  $fontFamily,
  $fontSize,
  $lineHeight,
  $fontWeight: normal,
  $fontStyle: normal
) {
  font-family: $fontFamily;
  font-style: $fontStyle;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
