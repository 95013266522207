@import "~styles/colors";
@import "~styles/typography";

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
}

.inputLabel {
  pointer-events: none;
}
